<template>

    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <customer-links-comp></customer-links-comp>

                <div class="board_list mt5" v-for="(item, index) in questionList" :key="index">
                    <div class="my_question">
              <span class="time">
                <i class="fa fa-question-circle-o"
                   aria-hidden="true"></i> {{item.createTime|datef('MM/DD HH:mm')}}</span>
                        <div class="qs" v-html="item.content">

                        </div>
                        <span class="replay_icon" v-if="item.contentReply">
            답변:  {{item.updateTime|datef('MM/DD HH:mm')}}
          </span>
                        <span class="replay_icon" v-else>
            답변: 답변대기중입니다
          </span>
                        <div class="aw" v-if="item.contentReply" v-html="item.contentReply">

                        </div>
                    </div>
                </div>
                <!--페이지-->
                <pagination :page-index="pageNum"
                            :total="total"
                            :page-size="pageSize"
                            @change="pageChange"
                            v-if="questionList.length > 0"></pagination>

                <div class="board_list">
                    <div class="my_question">
                        <span class="time"><i class="fa fa-pencil" aria-hidden="true"></i>글쓰기</span>
                        <quill-editor class="editor"
                                      ref="contentEditor"
                                      v-model="content"
                                      :options="editorOption"
                                      @blur="onEditorBlur($event)"
                                      @focus="onEditorFocus($event)"
                                      @ready="onEditorReady($event)"
                                      @change="onEditorChange($event)">
                        </quill-editor>
                        <div class="btn-area">
                            <button class="btn02" @click="saveQuestion()"><i class="fa fa-location-arrow"
                                                                             aria-hidden="true"></i> 등록
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <right-bar-banner-comp slot="rightBanner"></right-bar-banner-comp>
        </right-bar-comp>

        <foot-comp></foot-comp>

    </div>


</template>

<script>
    import RightBarComp from "../../components/RightBarComp";
    import SubTitle from "../../components/SubTitle";
    import {getQuestionList, saveQuestion} from "../../network/userRequest";
    import Pagination from "../../components/pagenation/Pagination";
    import LeftBarComp from "../../components/LeftBarComp";
    import FootComp from "../../components/FootComp";
    import TopbarComp from "../../components/TopbarComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import sportsConst from "../../common/sportsConst";
    import CustomerLinksComp from "../../components/links/CustomerLinksComp";

    export default {
        name: "CustomerCenter",
        components: {
            CustomerLinksComp,
            SportsLeftBarComp,
            RightBarBannerComp,
            TopbarComp, FootComp, LeftBarComp, Pagination, SubTitle, RightBarComp
        },
        data() {
            return {
                pageNum: 1,
                pageSize: 3,
                total: 1,
                orderBy: null,
                search: {},
                questionList: [],
                content: null,
                sportsConst,
                editorOption: {
                    modules: {
                        toolbar: [
                            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
                            [{font: []}], // 글자체
                            /*["blockquote", "code-block"], // 引用  代码块
                            [{ header: 1 }, { header: 2 }], // 1、2 级标题
                            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
                            [{ script: "sub" }, { script: "super" }], // 上标/下标
                            [{ indent: "-1" }, { indent: "+1" }], // 缩进
                            // [{'direction': 'rtl'}],                         // 文本方向
                            [{ size: ["small", false, "large", "huge"] }], // 字体大小
                            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
                            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                            [{ align: [] }], //
                            ["clean"], // 문구격식삭제
                            ["link", "image", "video"] // 링크、이미지、동영상*/
                        ], //기능바 세팅
                    },
                    placeholder: '', //提示
                    readyOnly: false, //是否只读
                    theme: 'bubble', //skin snow/bubble
                    syntax: false, //문법체크
                },
            }
        },

        methods: {
            saveQuestion() {
                let regx = /^(?=.*[가-힣ㄱ-ㅎ].*).{1,}$/
                if (!(regx.test(this.content))) {
                    //if(false){
                    this.$swal({
                        title: '한글이 포함된 내용으로 작성해주세요',
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    return false;
                }

                saveQuestion({'content': this.content}).then(res => {
                    if (res.data.success) {
                        this.content = ''
                        this.$swal({
                            title: '문의작성이 완료되였습니다',
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                        this.initQuestion()
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                })
            },
            initQuestion() {
                getQuestionList(this.pageNum, this.pageSize, this.orderBy).then(res => {
                    if (res.data.success) {
                        this.total = res.data.total
                        this.questionList = res.data.data
                    }
                })
            },
            //pagination.vue 에서 페이지 클릭 시 emit발송접수 메소드
            pageChange(page) {
                this.pageNum = page
                this.initQuestion()
            },

            onEditorBlur(editor) {
            },
            onEditorFocus(editor) {
            },
            onEditorReady(editor) {
            },
            onEditorChange(editor) {
                this.content = editor.html;
            },
        },
        computed: {
            editor() {
                return this.$refs.contentEditor.quillEditor
            }
        },
        mounted() {
        },
        created() {
            if(!this.$store.state.isLogin){
                this.$swal({
                    title: '로그인이 필요한 서비스 입니다.',
                    type: 'warning',
                    showCancelButton: false,
                    showConfirmButton: true
                })
                this.$router.push('/login')
                return false;
            }
            this.initQuestion()
        }
    }
</script>

<style scoped>
    .board_list{
        text-align: left;
        box-sizing: border-box;
        padding-left: 10px;
    }
    .board_list .my_question {
        font-size: 14px;
        box-sizing: border-box;
        padding: 10px 10px;
    }

    .my_question div {
        font-size: 14px;
    }

    .my_question .time {
        display: block;
        line-height: 30px;
        width: 100%;
        color: #f19f09;
    }

    .board_list .qs {
        box-sizing: border-box;
        padding: 10px;
    }

    .board_list .aw {
        display: block;
        line-height: 30px;
        color: #f5c639;
        background-color: #1c1c25;
        padding: 15px 15px;
    }

    .my_question .replay_icon {
        display: block;
        line-height: 30px;
        width: 100%;
        color: #00bfff;
    }

    .board_list .quill-editor {
        border: 1px solid #f19f09 !important;
        border-radius: 4px !important;
    }

    .board_list .btn-area {
        width: 100%;
        text-align: center;
        box-sizing: border-box;
        padding-top: 10px;
    }

    .board_list .btn-area button {
        width: 40%;
        display: inline-block;
        height: 40px;
        margin: 10px 10px;
    }

</style>